import { defineStore } from 'pinia'

interface BasketItem {
  id: string
  name: string
  quantity: number
  price: number
  totalPrice: number
  image: string
  quantity_identifier: string
}

export const useBasketStore = defineStore({
  id: 'basket',
  state: () => ({
    items: [] as BasketItem[],
  }),
  getters: {
    totalItems(): number {
      return this.items.reduce((total, item) => total + item.quantity, 0)
    },
    totalValue(): number {
      return this.items.reduce(
        (accum, item) => accum + Number(item.totalPrice),
        0
      )
    },
  },
  actions: {
    addItem(product: Omit<BasketItem, 'quantity' | 'totalPrice'>) {
      const foundItem = this.items.find((item) => item.id === product.id)
      if (foundItem) {
        foundItem.quantity++
        foundItem.totalPrice = foundItem.price * foundItem.quantity
      } else {
        this.items.push({ ...product, quantity: 1, totalPrice: product.price })
      }
    },
    clearBasket() {
      this.items = []
    },
    isBasketEmpty(): boolean {
      return this.items.length === 0
    },
    increaseItemQuantity(productId: string) {
      const foundItem = this.items.find((item) => item.id === productId)
      if (foundItem) {
        foundItem.quantity++
        foundItem.totalPrice = foundItem.price * foundItem.quantity
      }
    },
    decreaseItemQuantity(productId: string) {
      const foundItemIndex = this.items.findIndex(
        (item) => item.id === productId
      )
      if (foundItemIndex !== -1) {
        const foundItem = this.items[foundItemIndex]
        if (foundItem.quantity > 1) {
          foundItem.quantity--
          foundItem.totalPrice = foundItem.price * foundItem.quantity
        } else {
          // Remove the product from the basket if the quantity drops below 1
          this.items.splice(foundItemIndex, 1)
        }
      }
    },
  },
})
